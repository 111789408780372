import type {RouteObject} from 'react-router-dom'
import type {NavigatorRouteRegistration} from './app-routing-types'
import {DeferredRegistry} from './deferred-registry'

export interface AppRegistration {
  routes: NavigatorRouteRegistration[] | RouteObject[]
}

export interface NavigatorAppRegistration extends AppRegistration {
  App?: React.ComponentType<{children?: React.ReactNode}>
  routes: NavigatorRouteRegistration[]
}

export interface DataRouterAppRegistration extends AppRegistration {
  App?: never
  routes: RouteObject[]
}

export type NavigatorAppRegistrationFn = () => NavigatorAppRegistration

export type DataRouterAppRegistrationFn = () => DataRouterAppRegistration

export type NavigatorAppRegistrationObject = {type: 'NavigatorApp'; registration: NavigatorAppRegistrationFn}
export type DataRouterAppRegistrationObject = {type: 'DataRouterApp'; registration: DataRouterAppRegistrationFn}

export function createNavigatorAppRegistration(
  registration: NavigatorAppRegistrationFn,
): NavigatorAppRegistrationObject {
  return {
    type: 'NavigatorApp',
    registration,
  }
}
export function createDataRouterAppRegistration(
  registration: DataRouterAppRegistrationFn,
): DataRouterAppRegistrationObject {
  return {
    type: 'DataRouterApp',
    registration,
  }
}

export const reactNavigatorAppDeferredRegistry = new DeferredRegistry<NavigatorAppRegistrationObject>()
export const reactDataRouterAppDeferredRegistry = new DeferredRegistry<DataRouterAppRegistrationObject>()

export async function getReactDataRouterApp(appName: string) {
  return reactDataRouterAppDeferredRegistry.getRegistration(appName).promise
}
export async function getReactNavigatorApp(appName: string) {
  return reactNavigatorAppDeferredRegistry.getRegistration(appName).promise
}
