import {useRouteError, isRouteErrorResponse} from 'react-router-dom'

export const RouterErrorBoundary = () => {
  const routeError = useRouteError()
  /**
   * We might be routing to a different react app entirely
   * This is most likely to occur on back/forward navigation between apps
   *
   * If we move to a single app this won't be an issue
   */
  if (isRouteErrorResponse(routeError)) {
    if (routeError.status === 404) {
      return null
    }
  }

  throw routeError
}

try{ RouterErrorBoundary.displayName ||= 'RouterErrorBoundary' } catch {}