import {AnalyticsProvider} from '@github-ui/analytics-provider'
import {RenderPhaseProvider} from '@github-ui/render-phase-provider'
import {ToastContextProvider} from '@github-ui/toast/ToastContext'
import type {ReactNode} from 'react'
// eslint-disable-next-line no-restricted-imports
import {ThemeProvider} from '@primer/react'
import {QueryClientProvider} from '@github-ui/react-query'
import {PrimerFeatureFlags} from './PrimerFeatureFlags'
import {getQueryClient} from './query-client'
import useColorModes from './use-color-modes'

interface Props {
  appName: string
  children?: ReactNode
  wasServerRendered: boolean
}

const metadata = {}

/**
 * This component provides the _base_ context for both apps and partials.
 * It should provide everything needed to render with styles, themes, and i18n.
 */
export function BaseProviders({appName, children, wasServerRendered}: Props) {
  const {colorMode, dayScheme, nightScheme} = useColorModes()

  const queryClient = getQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <RenderPhaseProvider wasServerRendered={wasServerRendered}>
        <AnalyticsProvider appName={appName} category="" metadata={metadata}>
          <PrimerFeatureFlags>
            <ThemeProvider colorMode={colorMode} dayScheme={dayScheme} nightScheme={nightScheme} preventSSRMismatch>
              <ToastContextProvider>{children}</ToastContextProvider>
            </ThemeProvider>
          </PrimerFeatureFlags>
        </AnalyticsProvider>
      </RenderPhaseProvider>
    </QueryClientProvider>
  )
}

try{ BaseProviders.displayName ||= 'BaseProviders' } catch {}